import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { setLoading, setDialogState, resetDialogState } from '../../redux/features';
import { ReactComponent as EyeOff } from '../../assets/icons/EyeOff.svg';
import { ReactComponent as EyeOn } from '../../assets/icons/EyeOn.svg';
import { UnCheckedIcon, CheckedIcon } from '../../components/CheckBox';
import { ReactComponent as GoogleIcon } from '../../assets/icons/Google.svg';
import TextInput from '../../components/TextInput';
import { useGoogleLogin } from '@react-oauth/google';
import { CloseOutlined } from '@mui/icons-material';
import { ToastMessage } from '../../helpers/ToastMessage';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { baseURL } from '../../api/url';
import dayjs from 'dayjs';
import * as yup from 'yup';
import Axios from 'axios';
import { colors } from '../../styles/color';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';

interface FormErrors {
  email?: string;
  password?: string;
}
type props = {
  access_token?: any;
  accesstoken?: any;
};

const LoginModal = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [rememberMeBusiness, setrememberMeBusiness] = useState<any>(
    JSON.parse(localStorage.getItem('rememberMeBusiness') || 'false')
  );
  const [formData, setFormData] = useState({
    role: '3',
    email: JSON.parse(localStorage.getItem('emailBusiness') || '""'),
    password: JSON.parse(localStorage.getItem('passwordBusiness') || '""'),
  });
  const [expireTime, setExpireTime] = useState<number | undefined>(30);
  const [accessToken, setAccessToken, removeToken] = useCookies([
    'accessToken',
    'refreshToken',
    'googleToken',
    'role',
    'login',
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = window.location.pathname;
  const pathParts = currentPath.split('/');

  const getProfileData = async () => {
    dispatch(setLoading(true));
    try {
      const res = await dispatch(fetchProfileDetails());
      const data = res?.payload;
      dispatch(setProfileState(data));
      dispatch(setLoading(false));
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.error,
      });
      dispatch(setLoading(false));
    }
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 100);
  }

  const onSuccessData = async (response: props) => {
    dispatch(setLoading(true));
    const token = {
      role: 3,
      token: response?.access_token,
    };

    const responseData = await Axios.post(`${baseURL}/google-login`, token);
    const loginData = responseData?.data;
    if (loginData?.access_token) {
      setAccessTokenWithExpiry('accessToken', loginData?.access_token, loginData?.expires_in);
      setAccessTokenWithExpiry('refreshToken', loginData?.refresh_token, loginData?.expires_in);
      setAccessTokenWithExpiry('googleToken', response?.access_token, loginData?.expires_in);
      setAccessTokenWithExpiry('role', loginData.data?.role_id, loginData?.expires_in);
      ToastMessage({
        type: 'success',
        message: loginData.message,
      });
      // setAccessTokenWithExpiry('role', loginData?.data?.role_id, loginData?.expires_in);
      dispatch(setLoading(false));
      dispatch(resetDialogState());
      navigate('/');
    } else {
      ToastMessage({
        type: 'error',
        message: loginData.message,
      });
      dispatch(setLoading(false));
    }
  };

  const login = useGoogleLogin({
    onSuccess: (res) => onSuccessData(res),
  });

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');
  const schema = yup.object().shape({
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
  });

  const handleTokenExpiration = () => {
    removeAccessToken('googleToken');
    removeAccessToken('accessToken');
    removeAccessToken('refreshToken');
    removeAccessToken('role');
    // if (isGoogleLogin === true) {
    //   ;
    // } else {
    //   ;
    //   window.location.reload();
    // }
  };

  useEffect(() => {
    const expirationTimeInSeconds = expireTime ? expireTime : 30;
    const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;
    const timer = setTimeout(handleTokenExpiration, expirationTimeInMilliseconds);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const LoginAPICall = async () => {
    setFormErrors({});
    dispatch(setLoading(true));
    try {
      const response = await Axios.post(`${baseURL}/login`, formData);

      if (response?.data?.access_token) {
        ToastMessage({
          type: 'success',
          message: response.data.message,
        });
        setExpireTime(response?.data?.expires_in);

        setAccessTokenWithExpiry('accessToken', response?.data?.access_token, response?.data?.expires_in);
        setAccessTokenWithExpiry('refreshToken', response?.data?.refresh_token, response?.data?.expires_in);
        setAccessTokenWithExpiry('role', response?.data?.data?.role_id, response?.data?.expires_in);

        if (rememberMeBusiness === true) {
          localStorage.setItem('emailBusiness', JSON.stringify(formData.email));
          localStorage.setItem('passwordBusiness', JSON.stringify(formData.password));
        } else {
          localStorage.removeItem('emailBusiness');
          localStorage.removeItem('passwordBusiness');
        }
        dispatch(resetDialogState());
        localStorage.setItem('login', JSON.stringify(response?.data?.data));
        dispatch(setLoading(false));
        await getProfileData();

        if (pathParts[1] === 'frontend' && pathParts[2] === 'youtube-channel' && pathParts[3]) {
          const channelId = parseInt(pathParts[3], 10);
          if (!isNaN(channelId)) {
          } else {
          }
        } else {
          navigate('/');
        }
      } else {
        ToastMessage({
          type: 'error',
          message: response.data.message,
        });
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
      dispatch(setLoading(false));
    }
  };

  const removeAccessToken = (key: any) => {
    navigate(location?.pathname);
    // Cookies.remove(key, { path: '/' });
  };
  const setAccessTokenWithExpiry = (key: any, value: string, expiryInSeconds: number) => {
    const options = {
      path: '/',
      httpOnly: false,
      expires: dayjs().add(expiryInSeconds, 'seconds').toDate(),
      secure: true,
      sameSite: 'none' as 'none',
    };

    setAccessToken(key, value, options);

    setTimeout(() => {
      removeAccessToken(key);
    }, expiryInSeconds * 1000);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        LoginAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const name = evt.target.name;
    const value = evt.target.value.trim();
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Box sx={{ p: { md: '0 10px', xs: '0 0' } }}>
      <Box
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          right: { sm: '39px', xs: '20px' },
          top: { sm: '39px', xs: '20px' },
        }}
        onClick={() => {
          dispatch(resetDialogState());
        }}
      >
        <CloseOutlined
          sx={{
            color: 'white',
            width: '30px',
            height: '30px',
          }}
        />
      </Box>
      <Box sx={{ width: '100%', maxWidth: '438px', m: '60px auto' }}>
        <Box pt={{ md: 0, xs: '10px' }}>
          <Typography variant='h1'>Login</Typography>
        </Box>

        <Box
          sx={{
            mt: '36px',
          }}
        >
          <TextInput
            title='Email'
            onChange={handleChange}
            value={formData.email || ''}
            name='email'
            placeholder='Enter your email address'
          />{' '}
          {formErrors.email && (
            <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
              {formErrors.email}
            </div>
          )}
        </Box>
        <Box sx={{ mt: '17px' }}>
          <TextInput
            title='Password'
            name='password'
            onChange={handleChange}
            value={formData.password || ''}
            placeholder='Enter your password'
            type={showPassword ? 'text' : 'password'}
            EndIcon={
              <IconButton onClick={() => setShowPassword((show) => !show)}>
                {showPassword ? <EyeOn /> : <EyeOff />}
              </IconButton>
            }
          />
          {formErrors.password && (
            <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
              {formErrors.password}
            </div>
          )}
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          pt={'20px'}
          sx={{
            '& .MuiFormControlLabel-root': {
              mr: '0',
            },
          }}
        >
          <Box>
            <FormControlLabel
              sx={{
                '& .MuiTypography-body1': {
                  color: colors.gray[300],
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: { xs: '14px', sm: '16px' },
                  lineHeight: '1.5',
                  // width: { xs: "17px" },
                  // height: { xs: "17px" }
                },
              }}
              control={
                <Checkbox
                  className='checkbox-custom'
                  sx={{
                    '& span': {
                      width: { xs: '20px', sm: '24px !important' },
                      height: { xs: '20px', sm: '24px !important' },
                    },
                    '& span::before': {
                      width: { xs: '20px !important', sm: '24px !important' },
                      height: { xs: '20px !important', sm: '24px !important' },
                    },
                  }}
                  name='rememberMeBusiness'
                  checkedIcon={<CheckedIcon />}
                  icon={<UnCheckedIcon />}
                  disableRipple
                  checked={rememberMeBusiness}
                  onChange={(evt: any) => {
                    setrememberMeBusiness(evt.target.checked);
                    localStorage.setItem('rememberMeBusiness', evt.target.checked.toString());
                  }}
                />
              }
              label={'Remember me'}
            />
          </Box>
          <Box>
            <Button
              variant='text'
              onClick={() => {
                dispatch(
                  setDialogState({
                    loginDialog: false,
                    registerDialog: false,
                    modal: true,
                    otpDialog: { otpDialog: false, fromPageName: '' },
                    privacyDialog: false,
                    resetPassword: false,
                    successReset: false,
                    termsDialog: false,
                    forgotPassword: true,
                  })
                );
              }}
              sx={{
                color: colors.gray[300],
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: { xs: '14px', sm: '16px' },
                textAlign: { xs: 'end', sm: '' },
                lineHeight: '1.5',
                textDecoration: 'none',
              }}
            >
              Forgot password?
            </Button>
          </Box>
        </Box>
        <Box pt={'35px'}>
          <Button type='submit' sx={{ width: '100%' }} onClick={handleLogin}>
            Login
          </Button>
        </Box>
        <Box
          sx={{
            pt: '42px',
            '& .MuiDivider-root': {
              '&::before': {
                borderTop: `2px solid ${colors.dark[100]}`,
              },
              '&::after': {
                borderTop: `2px solid ${colors.dark[100]}`,
              },
              '& .MuiDivider-wrapper ': {
                pl: '26px',
                pr: '26px',
              },
            },
          }}
        >
          <Divider sx={{ color: colors.gray[300] }}>or continue with</Divider>
        </Box>
        <Box pt={'30px'}>
          <Button
            onClick={() => {
              login();
            }}
            variant='outlined'
            fullWidth
            sx={{
              color: 'white',
              border: `2px solid ${colors.dark[100]}`,
              fontWeight: '500',
            }}
            startIcon={<GoogleIcon />}
          >
            Sign in with google
          </Button>
        </Box>
        <Box mt={{ md: '69px', xs: '20px' }} mb={{ md: '0', xs: '20px' }} textAlign='center'>
          <Typography
            variant='body2'
            fontWeight='400'
            lineHeight={'180%'}
            sx={{ color: colors.gray[300] }}
            display={'inline'}
          >
            Don’t have an account?{' '}
            <Typography
              sx={{
                color: colors.basics.primary,
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              display={'inline'}
              variant='body2'
              onClick={() => {
                dispatch(
                  setDialogState({
                    modal: true,
                    registerDialog: true,
                    loginDialog: false,

                    otpDialog: { otpDialog: false, fromPageName: '' },
                    privacyDialog: false,
                    resetPassword: false,
                    successReset: false,
                    termsDialog: false,
                    forgotPassword: false,
                  })
                );
              }}
            >
              Sign Up
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginModal;
