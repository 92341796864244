import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Plus, YoutubeChannelLogo } from '../../assets/icons';
import { colors } from '../../styles/color';
interface props {
  profileData?: any;
  handleSubmit?: any;
}

export const EmptyYoutubePage = ({ profileData, handleSubmit }: props) => {
  return (
    <Box display={{ md: 'flex', xs: 'block' }} width='100%'>
      <Box
        sx={{
          background: colors.dark[800],
          borderRadius: '12px',
          mr: { md: '20px', xs: '0px' },
          flex: '40%',
          mb: { md: '0px', xs: '20px' },
          minHeight: { md: 'calc(100vh - 132px)', xs: '40vh' }
        }}
      >
        <Box
          p={'30px'}
          flexDirection='column'
          flex='1'
          display={'flex'}
          minHeight={{ md: 'calc(100% - 60px)', xs: '80vh' }}
          sx={{ position: 'relative' }}
        >
          <Box>
            <Typography>My Channel ({profileData?.channel_count ? profileData?.channel_count : 0})</Typography>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: ' 50%',
              m: 'auto',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box>
              <YoutubeChannelLogo />
            </Box>
            <Typography variant='body2' lineHeight={'24px'} color={colors.gray[500]}>
              No channels added yet
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              mt: 'auto',
              pt: '20px',
              '& .MuiButton-root': {
                border: `1px solid ${colors.dark[100]}`,
                '&:hover': {
                  border: `1px solid ${colors.dark[100]}`,
                },
              },
            }}
          >
            <Button
              variant='outlined'
              sx={{ width: '100%' }}
              startIcon={<Plus />}
              type='submit'
              onClick={handleSubmit}
            >
              Add Channel
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: colors.dark[600], borderRadius: '12px', flex: '60%' }}></Box>
    </Box>
  );
};
