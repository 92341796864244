import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const CookieAcceptPopup = () => {
  const [showBanner, setShowBanner] = useState(true);
  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", "true");
    setShowBanner(false);
  };
  const isCookieAccepted = localStorage.getItem("cookieAccepted") === "true";
  const isCookieRejected = localStorage.getItem("cookieRejected") === "true";
  return (
    <Box sx={{
      position:'fixed',
      bottom:'0',
      width:'100%',
     zIndex:'9999'
    }}>
      {showBanner && !isCookieAccepted && !isCookieRejected && (
        <Box
          display={"flex"}
          sx={{
            zIndex: 9999,
            borderRadius: "10px",
            minHeight: "90px",
            justifyContent: "space-between",
            background: "#343434",
            boxShadow: "0px 2px 30px 0px #000",
            padding:{sm: "0 40px",xs:"20px 20px"},
            width: "-webkit-fill-available",
            alignItems: "center",
            display:{sm:'flex', xs:"block"}
          }}
        >
          <Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, lineHeight: "160%", color: "#A9A9A9" }}
              >
                This website uses cookies to enhance the user experience. By
                using our services or clicking on Accept, you agree to our use
                of cookies.
                <Link
                  to="/cookie-policy"                
                >
                  {" "}
                  <span
                    style={{
                      color: "#A175FF",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "180%",
                      textTransform: "capitalize",
                      textDecoration:"underline",
                    }}
                  >
                    {" "}
                    Learn more
                  </span>
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flexShrink: "0" ,display:'flex', mr:{sm:'0', xs:"20px"}}} >
            <Button onClick={handleAccept}>
            I Consent
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
