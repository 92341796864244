import { Typography, Box, Button } from '@mui/material';
import {} from '@mui/system';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackButton, InstagramColorLogo, YouTubeColorLogo } from '../assets/icons';
import { CustomContainer } from '../components/CustomContainer';
import { ToastMessage } from '../helpers/ToastMessage';
import {
  fetchChannelSuggestionsList,
  setChannelSuggestionsState,
} from '../redux/features/channelSuggestionList/channelSuggestionListSlice';
import { colors } from '../styles/color';
import { setLoading } from '../redux/features';

export const AccountSetUp = () => {
  const [selectedButton, setSelectedButton] = React.useState('youtube');
  const [navigateLink, setNavigateLink] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttons = [
    { name: 'youtube', label: 'YouTube Channel', logo: <YouTubeColorLogo />, link: '/youtube-channel' },
    // { name: 'instagram', label: 'Instagram Account', logo: <InstagramColorLogo />, link: '/sign-up' },
  ];

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
  };

  // suggestion API
  type props = {
    access_token?: any;
    accesstoken?: any;
  };
  const onSuccessData = async (response: props) => {
    dispatch(setLoading(true));
    const res = await dispatch(fetchChannelSuggestionsList(response?.access_token));
    const data = res?.payload?.data;
    if (data) {
      dispatch(setChannelSuggestionsState(data));
      navigate(navigateLink);
      dispatch(setLoading(false));
    } else {
      dispatch(setChannelSuggestionsState([]));
      navigate(navigateLink);
      dispatch(setLoading(false));
    }
  };

  const login = useGoogleLogin({
    onSuccess: (res) => onSuccessData(res),
    // flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/youtube.readonly',
  });

  const onFailure: any = (response: any) => {
    console.error(response);
  };

  // const handleSubmit = async () => {
  //   login();
  //   // ToastMessage({ type: 'error', message: 'You can not add more then one channel!' });
  //   navigate('/dashboard/add-channel');
  // };

  const handleSetup = () => {
    const selected = buttons.find((button) => button?.name === selectedButton);
    if (selected !== undefined) {
      setNavigateLink(selected?.link);
      login();
    } else {
      ToastMessage({ type: 'error', message: 'Please select an option' });
    }
  };
  return (
    <Box>
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px 0px 35px', xs: '20px 10px' },
            display: 'block',
            minHeight: 'calc(100vh - 169px)',
          }}
        >
          <Box>
            <Button variant='text' onClick={() => navigate('/dashboard')}>
              <BackButton />
            </Button>
          </Box>
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '100px', xs: '30px' },
              mb: { sm: '60px', xs: '30px' },
              textAlign: 'center',
            }}
          >
            <Box mt={'39px'}>
              <Typography variant='h1'>Set Up Your Account</Typography>
            </Box>
            <Box mt={'7px'}>
              <Typography variant='body2' color={colors.gray[300]}>
                Hey! It’s looks like you are new... Let’s set up your account.
              </Typography>
            </Box>
            <Box display='flex' mt='46px' justifyContent={buttons.length === 1 ? 'center' : 'space-between}'}>
              {buttons?.map((button) => (
                <Box
                  key={button?.name}
                  onClick={() => handleButtonClick(button?.name)}
                  sx={{
                    width: '207px',
                    height: '209px',
                    background: colors.dark[600],
                    borderRadius: '10px',
                    border:
                      selectedButton === button?.name
                        ? `1.5px solid ${colors.basics.primary}`
                        : '1.5px solid transparent',
                  }}
                >
                  <Box
                    sx={{
                      height: '96px',
                      width: '96px',
                      m: 'auto',
                      background: selectedButton === button?.name ? '#a175ff26' : colors.dark[100],
                      borderRadius: '50px',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      mt: '30px ',
                      mb: '30px',
                    }}
                  >
                    {button.logo}
                  </Box>
                  <Box mb='24px'>
                    <Typography
                      variant='body2'
                      sx={{
                        color: selectedButton === button?.name ? colors.basics.primary : colors.basics.white,
                      }}
                    >
                      {button?.label}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box pt={'60px'}>
              <Button variant='contained' sx={{ width: '100%' }} type='submit' onClick={handleSetup}>
                Start Setup
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};
