import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../styles/color';
import { useNavigate } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { CustomContainer } from '../components/CustomContainer';
import { ToastContainer, toast } from 'react-toastify';
import { API } from '../api/services';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/features/loader/loaderSlice';
import { BackButton, ForgotLogo } from '../assets/icons';
import { ToastMessage } from '../helpers/ToastMessage';
import { setDialogState, setSignupState } from '../redux/features';
import * as yup from 'yup';
interface FormErrors {
  email?: string;
}
export const ForgotPassword = () => {
  //! Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({
    email: '',
  });
  const url = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);
  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      }),
  });

  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgetPassword = async () => {
    setFormErrors({});
    dispatch(setLoading(true));

    try {
      await emailSchema.validate({ email: formData.email });
      const response = await API.post('/forgot-password', {
        ...formData,
      });
      const forgotPasswordData = response?.data;
      if (forgotPasswordData?.status) {
        ToastMessage({
          type: 'success',
          message: response.data.message,
        });
        dispatch(setSignupState({ ...formData }));
        if (url === 'forgot-password') {
          navigate('/otp-verification', {
            state: { formData, fromPage: 'forgot-password' },
          });
        } else {
          dispatch(
            setDialogState({
              loginDialog: false,
              registerDialog: false,
              modal: true,
              otpDialog: { otpDialog: true, fromPageName: 'forgot-password' },
              privacyDialog: false,
              resetPassword: false,
              successReset: false,
              termsDialog: false,
              forgotPassword: false,
            })
          );
        }
      } else {
        toast.error('forgotPasswordData.message', forgotPasswordData.message);
      }
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
      setFormErrors(error);
    }
    dispatch(setLoading(false));
  };
  return (
    <Box>
      {' '}
      <ToastContainer />
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px', xs: '20px 10px' },
            display: 'block',
          }}
        >
          <Box>
            <Button
              variant='text'
              onClick={() =>
                url === 'forgot-password'
                  ? navigate('/sign-in')
                  : dispatch(
                      setDialogState({
                        loginDialog: true,
                        registerDialog: false,
                        modal: true,
                        otpDialog: { otpDialog: false, fromPageName: '' },
                        privacyDialog: false,
                        resetPassword: false,
                        successReset: false,
                        termsDialog: false,
                        forgotPassword: false,
                      })
                    )
              }
            >
              <BackButton />
            </Button>
          </Box>
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '100px', xs: '50px' },
              mb: { sm: '60px', xs: '25px' },

              textAlign: 'center',
            }}
          >
            <Box>
              <ForgotLogo />
            </Box>
            <Box mt={'39px'}>
              <Typography variant='h1'>Forgot Password?</Typography>
              <Typography variant='body2' color={colors.gray[300]}>
                Please enter your email address below. We will send you reset password link.
              </Typography>
            </Box>

            <Box sx={{ mt: '36px' }}>
              <TextInput
                title='Email'
                placeholder='Enter your email address'
                name='email'
                onChange={handleChange}
              />
              {formErrors.email && (
                <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
                  {formErrors.email}
                </div>
              )}
            </Box>
            <Box pt={'35px'}>
              <Button variant='contained' sx={{ width: '100%' }} onClick={handleForgetPassword}>
                Send Link
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};
