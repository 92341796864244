import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const c_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

root.render(
  <React.Fragment>
    <GoogleOAuthProvider clientId={c_id}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.Fragment>
);

reportWebVitals();
