import { Box, Button, ListItemText, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import NotificationModal from './NotificationModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import { ArrowDownGray, ArrowUpWhite, NotificationIcon } from '../../assets/icons';
import Topbar from '../../components/common/Topbar';
import { colors } from '../../styles/color';
import { TabPanel } from '../../components/TabPanel';
import { YoutubeDashboardPage } from './YouTubeDashboard';
import { MediaData } from '../../constants/menuItems';
import { setLoading } from '../../redux/features';
import { setAppState } from '../../redux/features/appStateSlice';

const DashboardPage = () => {
  const { profileData } = useSelector((state: any) => state?.profile);
  const { notificationDetails } = useSelector((state: any) => state.notification);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state: any) => state?.dahsboard);
  const [currentTab, setCurrentTab] = React.useState<any>(MediaData[0]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [selectValue, setSelectValue] = React.useState('');

  useEffect(() => {
    if (dashboardData?.channels?.length) setSelectValue(dashboardData?.channels[0])
  }, [dashboardData])

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    dispatch(setAppState("dashboard"))
    const getProfileData = async () => {
      dispatch(setLoading(true))
      const res = await dispatch(fetchProfileDetails());
      setTimeout(() => {
        dispatch(setProfileState(res?.payload));
        dispatch(setLoading(false))
      }, 100);
    };
   getProfileData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Box
        sx={{
          '& .MuiAppBar-root': {
            height: "100%",
            padding: "13px 0 ",
            position: 'sticky',
            top: 0,
          },
          '& .MuiPaper-root': {
            pr: 'none',
          },
        }}
      >
        {' '}
        <Topbar>
          <Box display={'flex'} width='100%'>
            <Box
              sx={{
                display: "flex", justifyContent: "center",
                '& .MuiToolbar-root': {
                  pl: { xl: 0, xs: '20px' },
                },
              }}
            >
              <Toolbar sx={{}}>
                {/* <Box
                  bgcolor={colors.dark[800]}
                  sx={{
                    border: `1px solid ${colors.dark[100]}`,
                    borderRadius: '8px',
                    p: '7px',
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                    '& .MuiTypography-root ': {
                      color: '#a9a9a9a99 !important',
                    },
                    '& .MuiTab-root ': {
                      textTransform: 'capitalize',
                    },
                    '& .MuiTabs-root': {
                      '& .Mui-selected': {
                        border: 'none',
                        '& #tabText': {
                          borderBottom: 'none !important',
                          color: `${colors.basics.white}`,
                        },
                        backgroundColor: colors.dark[600],
                        borderRadius: '6px',
                      },
                      '& .MuiButtonBase-root ': {
                        '&:hover': {
                          '& #tabText': { borderBottom: 'none !important' },
                          border: 'none',
                        },
                        height: 0,
                      },
                    },
                  }}
                >
                  <TabComponent tabs={MediaData} handleChange={handleChange} value={currentTab} />
                </Box> */}

                <Typography sx={{ fontSize: { xs: "16px", sm: "24px" } }} variant='h2'>Dashboard</Typography>
              </Toolbar>
            </Box>
            <Box justifyContent={"end"} width={"100%"} flexWrap={"wrap"} display='flex'>
              <Box
                display={profileData?.channel_count === 0 ? 'none' : 'flex'}
                sx={{
                  '& .MuiInputBase-root': {
                    border: `1px solid ${colors.dark[100]}`,
                    '& svg': {
                      width: '12px',
                    },
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              >
                <Select
                  sx={{
                    width: { xs: "100%", sm: '188px' },
                    padding: { sm: '10px 20px 10px 0px', xs: '16px' },
                    // paddingRight: { sm: '20px', xs: '16px' },
                    background: colors.dark[800],
                    color: colors.basics.white,
                    display: 'flex',
                    maxHeight: '60px',
                    height: '100%',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    '& svg': {
                      paddingLeft: '10px',
                      display: { xs: "none", sm: "flex" }
                    },
                    '& .MuiSelect-outlined': {
                      fontSize: '14px',
                      padding: { sm: '0 3px 0 0 !important', xs: '0 !important' },

                    },
                    '& .MuiSelect-select': {
                      '&>em': {
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        color: colors.gray[500],
                        fontWeight: 400,
                        lineHeight: '24px',
                        fontSize: '16px',
                        paddingLeft: '0px',
                      },
                    },
                    '& .MuiSelect-root': {
                      pl: '17px',
                    },
                    '& .MuiOutlinedInput-input': {
                      display: 'flex',
                    },
                    '& .MuiOutlinedInput-root': {
                      pl: '12px',
                    },
                    '& .MuiListItemText-root': {
                      display: "flex",
                      alignItems: 'center',
                      minWidth: 0,
                      // width: {sm: "100% !important" },

                    },
                    '& .MuiListItemText-root p': {
                      display: { xs: 'none', sm: "flex" },
                    },
                  }}
                  value={selectValue}
                  onChange={(e: any) => setSelectValue(e.target.value)}
                  IconComponent={(props) => {
                    return (
                      <Box display='flex'>
                        {props.className.includes('MuiSelect-iconOpen') ? (
                          <ArrowUpWhite />
                        ) : (
                          <ArrowDownGray />
                        )}
                      </Box>
                    );
                  }}
                >
                  {dashboardData?.channels?.map((item: any) => (
                    <MenuItem value={item} key={item.id} sx={{ display: 'flex' ,paddingLeft:'20px'}}>
                      <ListItemText sx={{ flexShrink: 0, display: "flex",paddingLeft:'17px' }} >
                        <Box
                          sx={{
                            display: "flex",
                            '& .img': {
                              width: { xs: "24px", sm: '32px' },
                              height: { xs: "24px", sm: '32px' },
                              borderRadius: '50px',
                              border: item?.channel_name
                                ? `1px solid ${colors.basics.primary}`
                                : `1px solid ${colors.basics.white}`,
                            },
                          }}
                        >
                          <img src={item?.image} className='img' alt='channel-img' />
                        </Box>
                      </ListItemText>
                      <ListItemText
                        sx={{
                          display: "flex",
                          '& .MuiListItemText-root': {
                            display: 'flex',
                            background: 'red',
                          }
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "14px", sm: "16px" }}
                          // p={{ xs: "0 15px", sm: 0 }}
                          variant='body2'
                          fontWeight={'24px'}
                          display='flex'
                          alignItems={'center'}
                          // ml='20px'
                          sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '100px',
                            overflow: 'hidden',
                            display: 'inline-block',
                            ml: '8px',
                          }}
                        >
                          {item?.channel_name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box
                sx={{
                  ml: '10px',
                  '& svg': { height: '20px', width: '20px', color: colors.gray[500] },
                  '& .MuiButton-root': {
                    border: `1px solid ${colors.dark[100]}`,
                    '&:hover': {
                      background: colors.dark[800],
                    },
                  },
                }}
              >
                <Button
                  onClick={handleClickOpen}
                  variant='text'
                  sx={{ background: colors.dark[800], p: '30px 20px' }}
                >
                  <Box sx={{ position: 'relative' }}>
                    {notificationDetails?.unread_count !== 0 &&
                      <Box
                        sx={{
                          width: '9px',
                          height: '9px',
                          borderRadius: '15px',
                          bgcolor: 'red',
                          position: 'absolute',
                          mt: '-4px',
                          ml: '9px',
                          border: `2px solid ${colors.dark[600]}`,
                        }}
                      ></Box>}
                    <NotificationIcon />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Topbar>
        <Box>
          <TabPanel currentTab={currentTab} tab='YouTube'>
            <Box>
              <YoutubeDashboardPage profileData={profileData} />
            </Box>
          </TabPanel>
          <TabPanel currentTab={currentTab} tab='Instagram'>
            <Box>
              <Typography>Instagram</Typography>
            </Box>
          </TabPanel>
        </Box>
      </Box >
      <NotificationModal settingNavigatePath={"/dashboard/settings"} open={open} setOpen={setOpen} />
    </Box >
  );
};

export default DashboardPage;
