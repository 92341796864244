import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { colors } from '../../styles/color';

type Props = {
  children: JSX.Element;
};

function Modal({ children }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { dialog } = useSelector((state: any) => state.dialog);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialog.modal}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1200px !important',
          backgroundColor: colors.dark[800],
          borderRadius: '24px',
          width: '100%',
          margin: { md: '32px', xs: '15px' },
          padding: '15px !important',
        },
        '& .MuiDialog-container': {
          marginTop: '96px',
          height: 'calc(100vh - 96px)',
          alignItems: 'unset',
        },
      }}
    >
      {children}
    </Dialog>
  );
}

export default Modal;
